import React from 'react';
import { createRoot } from 'react-dom/client';

import { ConfigProvider } from './contexts/ConfigContext';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

// Create the HTTP link to your GraphQL server
const httpLink = createHttpLink({
  uri: 'https://b2b.vendure.stage.amalcloud.net/admin-api?languageCode=en',
});
// Middleware to set the auth token on each request
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
    },
  };
});

const captureTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const authToken = context.response?.headers.get("Vendure-Auth-Token");
    if (authToken) {
      sessionStorage.setItem('authToken', authToken);
    }
    return response;
  });
});

const cache = new InMemoryCache();
const client = new ApolloClient({link: ApolloLink.from([authLink, captureTokenLink, httpLink]), cache});


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={client}>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </ApolloProvider>
);

reportWebVitals();
